<template>
	<div>
		<main-header></main-header>
		<bread-crumbs :crumbs="crumbs" @selected="selected"></bread-crumbs>
		<h1 class="page-title">자주하는 질문</h1>
		<faq-content></faq-content>
		<Footer />
	</div>
</template>

<script>
import MainHeader from '../components/MainHeader.vue';
import Footer from '../components/Footer.vue';
import FaqContent from '../components/FaqContent.vue';
import BreadCrumbs from '../components/common/BreadCrumbs.vue';
export default {
	components: { MainHeader, Footer, FaqContent, BreadCrumbs },
	data() {
		return {
			crumbs: [
				{ name: 'Home', link: '/' },
				{ name: '자주하는 질문', link: '/faq' },
			],
		};
	},
	methods: {
		selected(crumb) {
			console.log(crumb);
			this.$router.push(crumb.link);
		},
	},
};
</script>

<style></style>
