<template>
	<div class="flex-container--col--center">
		<VueFaqAccordion :items="myItems" />
	</div>
</template>

<script>
import VueFaqAccordion from 'vue-faq-accordion';
export default {
	components: {
		VueFaqAccordion,
	},

	props: {
		/**
		 * Array of items
		 * Object style {questionProperty: string, answerProperty: string, tabName: string}
		 * You can change object keys names using other props (questionProperty, answerProperty, tabName)
		 */
		items: {
			type: Array,
			required: true,
		},
		/**
		 * Key name of object in items array for specifying title of question
		 */
		questionProperty: {
			type: String,
			default: 'title',
		},
		/**
		 * Key name of object in items array for specifying content text of open question
		 */
		answerProperty: {
			type: String,
			default: 'value',
		},
		/**
		 * Key name of object in items array for specifying navigation tab name
		 */
		tabName: {
			type: String,
			default: 'category',
		},
		/**
		 * Color for hover and active tab/question
		 * possible format: 'red', '#F00', 'rgba(255, 0, 0, 1)'
		 */
		activeColor: {
			type: String,
			default: '#0A7DEC',
		},

		/**
		 * Color for borders
		 */
		borderColor: {
			type: String,
			default: '#9E9E9E',
		},

		/**
		 * Color for fonts
		 */
		fontColor: {
			type: String,
			default: '#000000',
		},
	},

	data() {
		return {
			myItems: [
				//1. 회원정보 관리
				{
					title: '1. 비회원 / 일반회원과 기업회원의 차이가 무엇인가요?',
					value:
						'① 비회원 - 홈페이지 내 서비스들의 기능 확인, 1:1 문의하기 가능 <br> ② 일반회원 - 홈페이지 내 서비스들의 기능 확인, 1:1 문의하기, 마이페이지 이용 가능<br> ③ 기업회원 - 홈페이지 내 모든 서비스 기능 확인, 1:1 문의하기, 솔루션 구매 가능<br><br>*해외기업일 경우 (해외) 기업회원 전환 양식 파일을 다운로드 및 작성하여 제출합니다. <br>*If you are an overseas company, download and fill out the (overseas) corporate member conversion form file and submit it.',
					category: '회원정보 관리',
				},
				{
					title: '2. 일반회원으로 서비스 구매가 가능한가요?',
					value:
						'FCTS 서비스는 기업회원만 구매 가능하여 일반회원일 경우 기업회원 전환 후 구매가 가능합니다.',
					category: '회원정보 관리',
				},
				{
					title: '3. 기업회원 전환 요청 후 서류 검토까지 얼마나 걸리나요?',
					value:
						'서류 접수 후 검토 완료까지 영업일 기준 3~5일 정도 소요될 수 있습니다.',
					category: '회원정보 관리',
				},
				{
					title: '4. 기업회원 전환 요청을 취소하고 싶은데 어떻게 하나요?',
					value:
						'기업회원 전환 요청을 취소하실 경우, FCTS 홈페이지 내 1:1 문의해주시기 바랍니다.',
					category: '회원정보 관리',
				},
				{
					title:
						'5. 기업회원 전환 요청이 정상적으로 처리되었는지 어떻게 확인할 수 있나요?',
					value:
						'기업회원 전환 요청 시, 내부에서 순차적으로 검토 후 이메일로 연락드리고 있습니다. <br>(추가적인 정보가 필요할 시 신청 담당자에게 서류를 요청할 수 있습니다.) <br><br>영업일 기준 7일 이후에도 기업회원 전환 승인이 되지 않았을 경우, <br>FCTS 홈페이지 내 1:1 문의를 통해 자세한 내용을 남겨주시기 바랍니다.',
					category: '회원정보 관리',
				},
				{
					title: '6. 회원 탈퇴는 어떻게 처리 되나요?',
					value:
						'FCTS는 정상적인 조건에서 회원이 요청한 날짜에 중지 일정에 따라 서비스를 중지시키거나 서비스 계약을 종료합니다. <br> 그러나 회원이 계약 해지를 요청한 날 납부해야 할 이용요금이 남아 있다면 해당 회원이 이용요금을 납부 완료한 후에야 해지 절차를 진행할 수 있습니다. <br><br>탈퇴 후에도 회원이 등록한 게시물은 그대로 남아 있으니, 삭제를 원하는 게시글이 있다면 탈퇴 전 삭제하시기 바랍니다. <br>탈퇴 후에는 회원 정보가 삭제되어 회원 여부를 확인할 수 없어, 게시글을 임의로 삭제해드릴 수 없습니다.<br><br>',
					category: '회원정보 관리',
				},
				// 2. 주문 및 결제
				{
					title:
						'1. 결제 방법을 선택한 후, 결제가 진행되지 않거나 오류가 발생하는데 어떻게 해야 하나요?',
					value:
						'FCTS 서비스 결제 시 결제가 정상적으로 되지 않고 오류가 발생하는 경우 다음과 같이 해보시기를 바랍니다.<br> ① 실행 중인 Internet Explorer 창을 모두 종료<br> ② 새로운 Internet Explorer 실행하여 주문 신청<br> ③ FCTS 서비스 결제 <br><br> 위와 같이 조치 후에도 계속 동일하게 오류가 발생하는 경우. FCTS 홈페이지 내 1:1 문의를 통해 *자세한 내용을 남겨주시기 바랍니다. <br><br>*오류가 발생한 화면 스크린 캡처, 자세한 오류 내용 설명 등을 기재',
					category: '주문 및 결제',
				},
				{
					title:
						'2. 기업회원으로 전환이 완료되었는데 서비스 구매는 어디서 하나요?',
					value:
						'FCTS 홈페이지 내에서 원하시는 서비스를 선택하고 결제하시면 구매가 완료됩니다.    FCTS 서비스의 자세한 기능들은 아래의 링크를 통해 확인 바랍니다.<br><br><button class="btn__link"><router-link to="/servicesercurity">보안 모듈러스</router-link></button> <br> <button class="btn__link"><router-link to="/">백신 FC-Anti Virus</router-link></button><br> <button class="btn__link"><router-link to="/servicecrypto">암호 스키마</router-link></button>',
					category: '주문 및 결제',
				},
				{
					title: '1. 서비스 구매 후 설치는 어떻게 하나요?',
					value:
						'개발자 사이트(링크) 이동 후, API 연동 및 이용 방식을 확인하여 사용해주시면 됩니다. <br>추가 사항에 대해서는 FCTS 홈페이지 내 1:1 문의해주시기 바랍니다.',
					category: '상품 문의',
				},
				{
					title: '1. 환불 정책은 어떻게 되나요?',
					value:
						'기업회원의 이용약관 방침을 기준으로 적용되며, 자세한 사항은 하단 내용을 참고하십시오. <br><br> ① 회원의 단순 변심 사유 : 구매 후 3일 이내 고객센터 문의 <br> ② 구매한 제품을 더 이상 원하지 않을 경우 : 구매 기준일로부터 90일 이내 제기 시 구매가의 70% 환불 가능 <br> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp *90일 이후 제기 시 해당 서비스의 결함이 있을 경우에만 환불 가능<br> ③ 서비스에 결함이 있는 경우 : 해당 담당자 확인 후 환불 여부 확인 진행(고객센터 문의) 구매 후 10일 이내에 문제 제기 시 교환 또는 구매가 환급 <br>④ 정상적인 사용상태에서 발생한 성능 기능상의 경우 : 구매 후 1년 이내에 문제 발생 시 서비스 교환 및 환불 진행 가능',
					category: '환불 문의',
				},
				{
					title: '2. 환불 요청을 했는데 언제 환불 되나요?',
					value:
						'FCTS에 환불 요청 후 결제 수단에 따라 환불 소요 기한은 다를 수 있습니다. <br><br>* 신용카드 : 카드사 승인 취소 (3 ~ 5영업일)<br> * 휴대폰결제 - 당월 취소 시 즉시 처리 - 결제월과 다른 월에 취소 요청 시 요청 계좌로 환불 (환불 요청일부터 +5영업일)<br> * 가상계좌 : 요청 계좌로 환불 (환불 요청일부터 +5영업일)<br> * 실시간 계좌이체: 해당 계좌로 입금 (환불 요청일부터 +1~3영업일)',
					category: '환불 문의',
				},
				{
					title: '1. 재계약 / 계약연장은 어떻게 진행되나요?',
					value:
						'저희가 기업회원에게 재계약 관련하여 약관에 별도로 명시하지 않는 한 이메일주소를 이용하여 서비스가 종료되기 30일 이전에 만료 사실을 기업회원에게 통지합니다.',
					category: '기타',
				},
				{
					title: '2. 투자 / 파트너십을 제안하고 싶은데 어떻게 하나요?',
					value:
						' 투자 / 파트너십 제안을 원하시는 경우, 해당 내용은 1:1 문의를 통해 자세한 내용을 남겨주시면 확인 후 담당자가 연락드리도록 하겠습니다.',
					category: '기타',
				},
			],
		};
	},
};
</script>

<style></style>
